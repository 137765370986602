import React, {Component} from "react";
import "../Elements/FromGroup/form_select.css"
import AscModal from "../Elements/Modal/Modal";
import {Card, Button, Row} from "react-bootstrap";
import InlineForm from "../Elements/FromGroup/InlineForm";
import moment from "moment";
import RecordCustomerModal from "./RecordCustomerModal";


export default class RecordPlaybackModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // modal item
            show: false,
            modalType: null,
            customer_name: "",
        };
    }

    getModalBranch = () => {
        switch (this.state.modalType) {
            case "addCustomer":
            case "editCustomer":
                return (
                    <RecordCustomerModal
                        show={this.state.show}
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        validationHandle = {this.validationHandle}
                        onTextChange = {this.onTextChange}
                        onClickHandle = {this.onClickHandle}/>
                );
            default:
                return null;
            }
    }

    //CustomerModal用関数
    propSetState = state => {
        this.setState(state);
    }

    onTextChange = (event) => {
        let value = (event && event.target && event.target.value) ? event.target.value : "";
        this.setState({customer_name: value});
        this.props.propSetState({customer_name: value});
    }

    validationHandle = param => {
        let validation_flag = false;
        switch (param) {
            case "addCustomer":
            case "editCustomer":
                validation_flag = !!this.state.customer_name;
                break;
            default:
                break;
        }
        return validation_flag;
    }

    onClickHandle = async(param) => {
        let {modalType} = this.state;
        let {customer_name,dest_tel_number,customer_id} = this.props.state;
        if(param === 'deleteCustomer'){
            try{
                if (window.confirm(this.props.langText.Message.Record_CustomerDelete)) {
                    await this.props.ascAxios('post', `Record/customerDelete`, {
                        customer_id
                    });
                    this.props.propSetState({customer_name:"", customer_id:""});
                    alert(this.props.langText.Message.DataDeleteSuccess);
                }
            } catch (err) {
                console.error(err);
                this.props.showErrorObjectMessage({message: 'ModalTypeError'});
            } finally {
                this.setState({show: false});
            }
        } else if (param === "voiceDelete") {
            try {
                if (window.confirm(this.props.langText.Message.Record_VoiceDelete)) {
                    await this.props.ascAxios('post', `Record/voiceDelete`, {
                        id: this.props.state.id
                    });
                    await this.props.propSetState({show: false});
                    await this.props.reactTableRefresh();
                }
            } catch (err) {
                console.error(err);
                this.props.showErrorObjectMessage({message: 'ModalTypeError'});
            }
        } else if(modalType){
            try {
                switch (modalType) {
                    case 'addCustomer':
                        let add_data = await this.props.ascAxios('post', `Record/customerAdd`, {
                            customer_name,
                            dest_tel_number
                        });
                        this.props.propSetState({customer_id: add_data.data.insert_data.id});
                        alert(this.props.langText.Message.DataInsertSuccess);
                        break;
                    case 'editCustomer':
                        await this.props.ascAxios('post', `Record/customerEdit`, {
                            customer_id,
                            customer_name
                        });
                        alert(this.props.langText.Message.DataUpdateSuccess);
                        break;
                    default:
                        this.showErrorObjectMessage('ModalTypeError');
                        break;
                }
            } catch (err) {
                console.error(err);
                this.props.showErrorObjectMessage({message: 'ModalTypeError'});
            } finally {
                this.setState({show: false});
            }
        }
    }

    viewVoiceMessage = (ht87_voice_log_to_text) => {
        try {
            let returnItems = [];
            switch (ht87_voice_log_to_text.status) {
                case "CONVERSION_COMPLETE":
                    if (ht87_voice_log_to_text.text_data_json) {
                        for (let i = 0; i < ht87_voice_log_to_text.text_data_json.length; i++) {
                            let position = "in" === ht87_voice_log_to_text.text_data_json[i].inout ? "left" : "right";
                            returnItems.push(
                                <React.Fragment key = {i}>
                                    <div id={"message-box-"+i} className={"message-div " + position}>
                                        <div className = {"message-box " + position}>
                                            <Row>
                                    <span className={"voice-time " +position}>
                                        {this.props.secToTime(Math.floor(ht87_voice_log_to_text.text_data_json[i].start))}
                                    </span>
                                            </Row>
                                            {ht87_voice_log_to_text.text_data_json[i].text}
                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        }
                    } else {
                        returnItems.push(<div>
                            {this.props.langText.Body.audio2textNotFound}
                        </div>);
                    }
                    break;
                case "REQUEST_ACCEPTED":
                    returnItems.push(<div>
                        {this.props.langText.Body.audio2textWorking}
                    </div>);
                    break;
                default:
                    returnItems.push(<div>
                        {this.props.langText.Body.audio2textFailed}
                    </div>);
                    break
            }

            return returnItems;
        } catch (e) {
            console.error(e);
            this.props.showErrorObjectMessage(e);
        }
    }

    render() {
        let dest_tel_number;
        let btn = null;
        if (this.props.state.dest_tel_number && this.props.options
            && this.props.options.customer_infos && this.props.state.customer_name) {
            dest_tel_number = this.props.state.customer_name + "(" + this.props.state.dest_tel_number + ")";
        } else if (this.props.state.dest_tel_number) {
            dest_tel_number = this.props.state.dest_tel_number;
        } else {
            dest_tel_number = "---";
        }
        if (this.props.currentPermission.update && this.props.options
            && this.props.options.customer_infos && this.props.state.customer_name) {
            btn = <Button className = "customerAddBtn" onClick ={() => this.setState({
                show:true, modalType:"editCustomer", customer_name: this.props.state.customer_name
            })}>
                {this.props.langText.Body.CustomerEdit}
            </Button>;
        } else if (this.props.currentPermission.update && this.props.options && this.props.options.customer_infos) {
            btn = <Button className = "customerAddBtn" onClick ={() => this.setState({
                show:true, modalType:"addCustomer", customer_name: this.props.state.customer_name
            })}>
                {this.props.langText.Body.CustomerAdd}
            </Button>;
        }

        return (
            <AscModal
                state={this.props.state}
                currentPermission={this.props.currentPermission}
                onClick={this.onClickHandle}
                propSetState={this.props.propSetState}
                langText={this.props.langText}>
                <Card variant="info">
                    <Card.Body>
                        <InlineForm
                            key="external"
                            readOnly
                            controlId={0}
                            label={this.props.langText.Body.ExternalNumber}>
                            {this.props.state.external_number ? this.props.state.external_number : "-"}
                        </InlineForm>
                        <InlineForm
                            key="dest"
                            readOnly
                            controlId={0}
                            label={this.props.langText.Body.DestTelNumber}>
                            {dest_tel_number}
                            {btn}
                        </InlineForm>
                        <InlineForm
                            key="inout"
                            readOnly
                            controlId={0}
                            label={this.props.langText.Body.InOutType}>
                            {this.props.state.inout_type === "in" ? this.props.langText.Body.Incoming : this.props.langText.Body.Outgoing}
                        </InlineForm>
                        <InlineForm
                            key="date"
                            readOnly
                            controlId={0}
                            label={this.props.langText.Body.CallDate}>
                            {moment(this.props.state.answer_datetime).utc().add(9, "h").format("YYYY-MM-DD HH:mm:ss")}
                        </InlineForm>
                        <InlineForm
                            key="audio"
                            readOnly
                            controlId={0}
                            label={this.props.langText.Body.Voice}>
                            <audio
                                key = "recorded-voice-audio"
                                className = "recorded-voice-audio"
                                controls = {true}
                                controlsList = "nodownload"
                                autoPlay = {false}>
                                <source  src = {this.props.state.url} type="audio/mp3"/>
                            </audio>
                        </InlineForm>
                        {this.props.state.ht87_voice_log_to_text ?
                            <InlineForm
                                key="text"
                                readOnly
                                controlId={0}
                            label={this.props.langText.Body.audio2text}>
                                <div className={"voice-text-panel"}>
                                    {this.viewVoiceMessage(this.props.state.ht87_voice_log_to_text)}
                                </div>
                            </InlineForm> : null}

                    </Card.Body>
                </Card>
                {this.getModalBranch()}
            </AscModal>
        );
    }
}
